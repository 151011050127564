<template>
  <div class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card class="mb-12">
      <page-header :title="template ? `Create a new ${template.name} challenge` : 'Create a new event'" :img="template && template.img" compact />

      <v-stepper v-if="template" :value="1" tile :elevation="0">
        <EventTemplateWizardHeader :template="template" :step="1" />
      </v-stepper>

        <v-card-text v-if="tenant.id == 'cofi' && !$route.query.templateId && templates && !templateId" class="pb-0 mt-4">
          <p class="mb-0 text-center">Start a new event from scratch. Optionally, you can jump start your event with one of our <strong>ready-to-go challenges</strong>.</p>
          <v-slide-group
            v-model="templateId"
            show-arrows
             class="ms-8 me-8"
          >
            <!-- <v-slide-item :value="null" v-slot="{ active, toggle }" class="">
              <v-card @click="selectTemplate(null)" color="white" :class="{'ma-1 pa-2  d-flex flex-column justify-end':true, 'selected': active || templateId == null}" width="150px" height="100px">
                <h3>Custom</h3>
                <p>Start from scratch.</p>
              </v-card>
            </v-slide-item> -->

            <v-slide-item
              v-for="(item, idx) in templates.filter(x => x.featured).slice(0, 3)"
              :key="idx"
            >
              <EventTemplateCard :item="item" :to="{name: 'challengeTemplateView', params: {id: item.id}, query: { referral: $route.query.referral, license: $route.query.license, participants: $route.query.participants, support: $route.query.support, currency: $route.query.currency, duration: $route.query.duration, groupId: $route.query.groupId }}" mini class="ma-1" style="width:150px"/>
            </v-slide-item>

            <v-slide-item>
              <v-card :to="{name: 'eventmanagerStart', query: { referral: $route.query.referral, license: $route.query.license, participants: $route.query.participants, support: $route.query.support, currency: $route.query.currency, duration: $route.query.duration, groupId: $route.query.groupId }}" color="grey lighten-3" class="ma-1 pa-2 d-flex flex-column justify-end" width="150px" height="100px">
                <h3>View All <v-icon small class="ms-2">fa-chevron-right</v-icon></h3>
                <p>Explore all ready-to-go challenges</p>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <div class="full-width-separator text-center mt-6">
            <span>or</span>
          </div>
          <p class="text-center">Create your own event from scratch.</p>
          <p v-if="template && template.summary">{{ template.summary }}</p>      
        </v-card-text>      
        <v-alert v-if="$route.query.participants && $route.query.support" type="info" tile class="">
          <p>You will be able to complete your payment once you have prepared your event.</p>
        </v-alert>
        <v-card-text>      
          <h3>Name your event</h3>
          <v-alert v-if="tenant.id !== 'cofi' && tenant.id !== 'wmm'" type="info" class="mb-16">
            <p>This wizard is to <strong>create a new event</strong> and configure and manage this event.</p>
            <p>Are you a participant of an <strong>existing event</strong>? Please look in your mailbox for the invite email (also check your junk/spam folder) and do <i>not</i> create a new event.</p>
          </v-alert>
          <p class="mb-0">The name is used in the app and in emails to identify this event. You can change the name later on.</p>
          <v-alert v-if="error" type="error">{{error}}</v-alert>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field 
              v-model="name" 
              :rules="nameRules" 
              autofocus
              label="How would you like to name your event? (required)" 
              prepend-icon="fa-pencil-alt"
              required>
            </v-text-field>

            <div v-if="tenant.id !== 'cofi' && tenant.id !== 'wmm'">
              <h3>Select your Event Type (optional)</h3>
              <p>How can you best describe the type of this event? Skip this question when you're not sure (yet).</p>
              <v-btn-toggle v-model="type" class="scoring-group">
                <v-btn value="CHALLENGE">
                  <v-icon x-large color="primary">fa fa-watch-fitness</v-icon>
                  <span>(Virtual)</span>
                  <strong>Challenge</strong>
                </v-btn>
                <v-btn v-if="tenant.id !== 'cofi'" value="HYBRID">
                  <v-icon x-large color="primary">fa fa-bullseye</v-icon>
                  <strong>Hybrid Event</strong>
                  <span>(Challenge &amp;</span>
                  <span>In-Person)</span>
                </v-btn>
                <v-btn v-if="tenant.id !== 'cofi'" value="RESULTS">
                  <v-icon x-large color="primary">fa fa-flag-checkered</v-icon>
                  <span>(In-Person)</span>
                  <strong>Race Results</strong>
                </v-btn>
                <v-btn v-if="tenant.id !== 'cofi'" value="TRACK">
                  <v-icon x-large color="primary">fa fa-map-signs</v-icon>
                  <span>(OnCourse365)</span>
                  <strong>Trail</strong>
                </v-btn>
                <v-btn v-if="!tenant.isDefault" value="STEPS">
                  <v-icon x-large color="primary">fa fa-shoe-prints</v-icon>
                  <strong>Step</strong>
                  <span>Challenge</span>
                </v-btn>
                <v-btn value="_">
                  <v-icon x-large color="primary">fa fa-ellipsis-h</v-icon>
                  <strong>Other /</strong>
                  <span>Don't know</span>
                </v-btn>
              </v-btn-toggle>
              <p v-if="type === 'CHALLENGE'">A (virtual) challenge which is based on fitness activities.</p>
              <p v-else-if="type === 'HYBRID'">A combination of an in-person event with a virtual component added to it.</p>
              <p v-else-if="type === 'TRACK'">A year-round (or limited time) event on a (set of) specific trails.</p>
              <p v-else-if="type === 'RESULTS'">An in-person race for which race day results may be uploaded.</p>
              <p v-else-if="type === 'STEPS'">Step challenges are based on the daily total steps, regardless of whether this was during an activity or just walking around.</p>
            </div>

            <div v-if="tenant.isDefault">
              <h3 class="mt-4">App Configuration (optional)</h3>
              <p>
                Would you like to enable free integration with <router-link :to="{name: 'get'}">our iPhone and Android app</router-link>? 
                Interested in having a custom-made app for your event? <router-link :to="{name: 'contact'}">Contact us</router-link> to discuss possibilities.
              </p>
              <v-switch v-model="enableApp" label="Enable app" persistent-hint hint="Enable to allow participants to use our app." />
            </div>

            <div>
              <h3 class="mt-4">Event Window</h3>
              <p v-if="type === 'RESULTS'" class="mb-0">Select the date of the event.</p>
              <p v-else class="mb-0">Select the event window. Only activities between start and end dates may count for this event. You may start inviting users to join before the start date and keep access to the data after the end date.</p>
              <v-row>
                <v-col cols="12" md="6" class="">
                  <DateWithTimeZonePicker v-model="from" :rules="requiredDateRules" :timeZone="timeZoneOlson" label="Start date (included)" @input="fromDateChanged"/>
                </v-col>
                <v-col cols="12" md="6" class="">
                  <DateWithTimeZonePicker v-model="till" :rules="requiredDateRules" :timeZone="timeZoneOlson" label="End date (included)" />
                </v-col>
              </v-row>
            </div>

            <div>
              <h3 class="mt-0">Organization</h3>
              <p>
                Would you like to link this event to your organization? This allows you to group multiple events within one organization page. 
                Please select this option to enable subscription options.
              </p>

            <v-radio-group v-model="orgMode">
              <v-radio
                v-if="licenseMode !== 'subscription'"
                value="NONE"
                label="Do not link to an organization"
                />

              <v-radio
                value="CREATE"
                label="Create a new organization"
                />
              <v-text-field v-if="orgMode == 'CREATE'"
                v-model="orgName"
                label="Name of the organization"
                class="ml-8"
                />


              <v-radio
                value="LINK"
                label="Link with an existing organization"
                />
              <v-select v-if="orgMode == 'LINK' && orgs"
                v-model="selectedOrgId"
                :items="orgs"
                item-text="name"
                item-value="id"
                label="Select your organization"
                class="ml-8"
                />
                <v-alert v-if="orgMode == 'LINK' && !selectedOrgId && (!orgs || !orgs.length)" type="info">No organization found. Please create a new organization instead.</v-alert>
              </v-radio-group>              
            </div>

            <div>
              <v-checkbox v-model="agreedToTerms" ref="termsCheckbox" :rules="cloudTermsRules">
                <template v-slot:label>
                  <div class="d-flex" style="flex-direction:column;">
                    <i18n path="account.register.terms_privacy_v2" tag="div" class="">
                      <a target="_blank" @click.stop="" href='/privacypolicy'>{{$t('account.register.terms_privacy_privacy')}}</a>
                      <a target="_blank" @click.stop="" href='/cloudterms'>Cloud Terms and Conditions</a>
                    </i18n>
                  </div>
                </template>
              </v-checkbox>
            </div>
            <v-alert v-if="error" type="error">{{error}}</v-alert>


          </v-form>
        </v-card-text>   
        <v-card-actions class="pr-4 pb-4">
          <v-btn text v-if="$store.state.context === 'microsoft-teams'" to="/apps/teams/content">Back</v-btn>
          <v-spacer />
          <v-btn large :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">{{ template ? 'Next' : 'Create event' }}</v-btn>
        </v-card-actions>
    </v-card>

    <LoginOrRegisterDialog ref="loginForm" mode="REGISTER" message="In order to create your event you'd need to either create a new account or login with your current account." />

  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import assetsService from "@/services/assetsService";
import profileService from "@/services/profileService";
import orgManagerService from "@/services/orgManagerService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import EventTemplateCard from '@/components/templates/EventTemplateCard.vue'
import EventTemplateWizardHeader from '@/components/eventmanager/EventTemplateWizardHeader.vue'
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import PageHeader from '@/components/PageHeader.vue';
import LoginOrRegisterDialog from '@/components/LoginOrRegisterDialog.vue';
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    Header,
    DateWithTimeZonePicker,
    LoginOrRegisterDialog,
    EventTemplateWizardHeader,
    EventTemplateCard,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      breadcrumbItems: [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Create Event', disabled: true },
      ],
      agreedToTerms: false,
      valid: true,
      error: null,
      timeZoneOlson: null,
      name: "",
      type: null,
      orgs: null,
      from: null,
      till: null,
      orgMode: 'NONE',
      orgName: null,
      licenseMode: null,
      selectedOrgId: null,
      enableApp: null,
      templates: null,
      template: null,
      templateId: null,
      nameRules: [
        v => !!v || "Please enter the event name",
      ],
      requiredDateRules: [
        v => !!v || "Please select date",
      ],
      showLoginDialog: false,
      cloudTermsRules: [
        v => !!v || "Please confirm you have read and agree to the Cloud Terms and Conditions.",
      ]
    };
  },
  async mounted() {
    await this.getProfile();
    this.timeZoneOlson = DateTime.now().zoneName;
    this.licenseMode = this.$route.query.mode;
    if (this.$route.query.templateId) {
      let template = (await assetsService.getEventTemplate(this.$route.query.templateId)).data
      await this.selectTemplate(template);
    }
    if (this.$route.query.mode == 'subscription') {
      this.$nextTick(() => {
        this.orgMode = 'CREATE';
      });
      
    }

    this.templates = (await assetsService.getEventTemplates()).data.data;
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
      if (this.showLoginDialog && user) {
        // logged in during the process, now start trial
        //this.submit();
      }
    });
  },
  methods: {
    async selectTemplate(item) {
      if (item) {
        this.template = item;
        this.templateId = item.id;
      }
      else {
        this.template = null;
        this.templateId = null;
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {

        if (this.orgMode == 'CREATE' && (!this.orgName || !this.orgName.length)) {
          this.error = 'Please enter the name of your organization.';
          return;
        }
        if (this.orgMode == 'LINK' && (!this.selectedOrgId)) {
          this.error = 'Please select the organization you want to link this event to.';
          return;
        }
        if (this.user) {
          if (this.orgMode === 'CREATE') {
            var response = await orgManagerService.create({
              name: this.orgName,
            });
            this.$analytics.event('create_org');
            this.selectedOrgId = response.data.id;
            //this.orgMode = "LINK";
            //linkResponse = await eventManagerService.linkOrg(this.event.id, response.data.id);
            //this.$router.push({ name: 'eventmanagerView', params: { id: response.data.id } });
          }

          try {
            var response = await eventManagerService.create({
              name: this.name,
              type: this.type === '_' ? null : this.type,
              from: this.from,
              till: this.till,
              enable_app: this.enableApp,
              timezone: this.timeZoneOlson,
              license: this.$route.query.license,
              requested_participants: this.$route.query.participants,
              requested_support: this.$route.query.support,
              requested_currency: this.$route.query.currency,
              requested_duration: this.$route.query.duration,
              referral: this.$route.query.referral,
              group_id: this.$route.query.groupId || this.selectedOrgId,
              org_name: this.orgName,
              template_id: this.templateId,
              map_template_id: this.$route.query.wellbeingPackId,
              wellbeing_pack_id: this.$route.query.mapTemplateId,
              slack_channel_id: this.$route.query.slackChannel,
              teams_info: this.$route.query.teamsInfoJson ? JSON.parse(this.$route.query.teamsInfoJson) : null,
            });
            this.$analytics.event('create_event');
            if (this.$route.query.templateId) {
              this.$router.push({ name:'eventmanagerConfigTemplate', params: {id: response.data.id}, query: { state: 'created', template: this.$route.query.templateId } });
            }
            else {
              this.$router.push({ name: 'eventmanagerView', params: { id: response.data.id }, query: { state: 'created' } });
            }
          }
          catch (ex) {
            this.error = ex.response.data.msg;
            //console.log('ERR', ex, ex.response);
          }
        }
        else {
          this.showLoginDialog = true;
          this.$refs.loginForm.open();
        }
      }
    },

    async getProfile() {
      if (this.user) {
        this.orgs = (await orgManagerService.getOwnedEvents()).data.data;

        //var response = await profileService.get();
        //this.profile = response.data;
      }
    },

    async fromDateChanged(value) {
      if (this.type === 'RESULTS') {
        // sync them to simplify setup
        this.till = value;
      }
    },

  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
  .scoring-group {
    width: 100%;
    flex-flow: row wrap; 
    button { 
      flex-grow: 1; flex-basis: 0; height: 120px !important;
      min-width: 150px !important;
      .v-btn__content {
        flex-direction: column;
        * {display: flex !important;}
        i { margin-bottom: 10px;}
      }
      //i { display: block !important; margin-bottom: 10px;}
      //.v-btn__content span { display: block !important; background: red!important; }
    }

  }
  .scoring-group::before/*, .scoring-group::after*/ {
    content: '';
    /* from https://stackoverflow.com/questions/29732575/how-to-specify-line-breaks-in-a-multi-line-flexbox-layout 
    flex-basis: 100%; height: 0; margin: 0; border: 0;
    order: 1;*/
  }
  .v-slide-group .selected { outline: var(--v-primary-base) solid 3px;}
  .v-slide-group .v-slide-group__prev--disabled { display: none; }
  </style>

